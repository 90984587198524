import React, { useState } from 'react'
import {
  Container,
  Segment,
  Step
} from 'semantic-ui-react'
import styled from 'styled-components'
import CogBackgroundStyled from './CogBackgroundStyled.js'
import StepTemplateUrl from './StepTemplateUrl'
import StepDefineData from './StepDefineData'
import StepBuild from './StepBuild'

const Header1Styled = styled.h1`
  &&& {
    font-size: 4rem;
    font-weight: 600;
    color: hsl(198, 100%, 7%)
    text-shadow: 2px 2px hsla(0, 0%, 0%, 0.20);
  }
`;

const LargeParagraphStyled = styled.div`
  &&& {
    font-size: 1.5rem;
    font-weight: 700;
    color: hsl(198, 100%, 18%)
    text-shadow: 2px 2px hsla(0, 0%, 0%, 0.20);
  }
`;

export default function Home() {
  const [step, setStep] = useState(1);
  const [templateProject, setTemplateProject] = useState();
  const [codeSandboxUrl, setCodeSandboxUrl] = useState();
  const [done, setDone] = useState();

  return (
    <div>
      <CogBackgroundStyled>
        <Container>
          
          <Header1Styled>
            Brodeo Builder
          </Header1Styled>
          
          <LargeParagraphStyled>
              Create 80% of your app in minutes!
          </LargeParagraphStyled>
          
        </Container>
        </CogBackgroundStyled>

      <Container>
        
        <br/><br/>

          <Step.Group ordered>
            <Step active={step===1} completed={step>1}>
              <Step.Content>
                <Step.Title>Choose template</Step.Title>
                <Step.Description>Pick a starter project or build your own.</Step.Description>
              </Step.Content>
            </Step>

            <Step active={step===2} completed={step>2}>
              <Step.Content>
                <Step.Title>Define your data</Step.Title>
                <Step.Description>Data is the soul of your app.</Step.Description>
              </Step.Content>
            </Step>

            <Step active={step===3} completed={done}>
              <Step.Content>
                <Step.Title>Build your app</Step.Title>
                <Step.Description>Get hacking!</Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>

        <Segment placeholder>      
        {
          step === 1 ? 
          <StepTemplateUrl 
          onNext={(newTemplateProject) => {
            setTemplateProject(newTemplateProject)
            setStep(2)
          }}
          />
          : step === 2 ? 
          <StepDefineData
          {...{templateProject}} 
          onNext={(url) => {
            setCodeSandboxUrl(url)
            setStep(3)
          }}
          />
          : step === 3 && 
          <StepBuild 
          {...{codeSandboxUrl}}
          onBuild={() => {
            setDone(true)
          }}
          onStartOver={() => {
            setTemplateProject('')
            setCodeSandboxUrl('')
            setDone(false)
            setStep(1)
          }}
          />
        }
        </Segment>
        
        <p></p>
        
      </Container>
    </div>
  );
}