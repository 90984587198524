import React from 'react'

const AppStateContext = React.createContext()
const AppStateDispatchContext = React.createContext()

function appStateReducer(state, action) {
  switch (action.type) {
    case 'set_base_url': {
      return {baseUrl: action.baseUrl}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AppStateProvider({initialState, children}) {
  const [state, dispatch] = React.useReducer(appStateReducer, initialState)
  return (
    <AppStateContext.Provider value={state}>
      <AppStateDispatchContext.Provider value={dispatch}>
        {children}
      </AppStateDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

function useAppState() {
  const context = React.useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateProvider')
  }
  return context
}

function useAppStateDispatch() {
  const context = React.useContext(AppStateDispatchContext)
  if (context === undefined) {
    throw new Error('useAppStateDispatch must be used within a AppStateProvider')
  }
  return context
}

export {AppStateProvider, useAppState, useAppStateDispatch}