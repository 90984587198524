import React, { useState } from 'react'
import {
  Segment,
  Message,
  Input,
  Button
} from 'semantic-ui-react'
import axios from 'axios'
import beautify from 'json-beautify'
import { useAppState } from '../hooks/appStateContext'

export default function StepTemplateUrl({onNext}) {
  const [url, setUrl] = useState('');
  const [gitHub, setGitHub] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const appState = useAppState();
  
  const handleInputChange = ({target}) => {
    setUrl(target.value)
    setGitHub(parseUrl(target.value))
  }
  
  const parseUrl = url => {
    let owner = '';
    let repo = '';

    if (!url)
      return null
    
    const urlStub = url.replace("https://github.com/", "")
    const stubList = urlStub.split('/')
    if (stubList.length !== 2)
      return null

    owner = stubList[0];
    repo = stubList[1];
        
    return {owner, repo}
  }
  
  const validateBroConfig = broConfig => {
    if (!broConfig)
      throw new Error('The .bro-config file is not valid. Please verify the repo has a .bro-config file.')

    return true
  }

  const handleNext = () => {
    const fetch = async () => {
      setFetching(true)
      setError(null)

      try {
        let url = `${appState.baseUrl}api/repo/${gitHub.owner}/${gitHub.repo}`
        const response = await axios.get(url)

        if (!!response.data && !response.errors && validateBroConfig(response.data)) {
          const templateProject = {
            owner: gitHub.owner,
            repo: gitHub.repo,
            defaultDataDefinition: beautify(response.data, null, '  ', 100)
          }
          setFetching(false)
          onNext(templateProject)
        }
        else {
          setError('Something went wrong fetching the repo you specified.  Please verify the repo has a .bro-config file.');
          setFetching(false)
        }
      }
      catch (err) {
        setError(err?.response?.data ?? 'Something went wrong. Please verify the url and repo.');
        setFetching(false)
      }            
    }
    fetch();
  }

  return (
    <div>
      <Message info>
        <Message.Header>Template URL</Message.Header>
        <p>
          Enter the GitHub URL of a Brodeo Builder template project.
        </p>
      </Message >

      { !!error &&    
        <Message 
          error
          icon='exclamation'
          header='Error'
          content={error}
        />
      }

      <Segment vertical>
        <Input
          icon='globe' iconPosition='left' 
          placeholder='GitHub URL' 
          fluid
          value={url}
          onChange={handleInputChange} 
        />
      </Segment>
      <Segment vertical>
        <Button primary onClick={handleNext} loading={fetching} >Next</Button>
      </Segment>

    </div>
  )
}