import React from 'react'
import {
  Segment,
  Message,
  Button
} from 'semantic-ui-react'

export default function StepBuild({codeSandboxUrl, onBuild, onStartOver}) {
  const handleBuild = () => {  
    var win = window.open(codeSandboxUrl, '_blank')
    win.focus()
    
    onBuild()
  }

  return (
    <div>
      <Message info>
        <Message.Header>Build your app</Message.Header>
        <p>
          All done! That wasn't so bad!
        </p>
        <p>
          Click the button to see your app come to life! You will see your project in CodeSandbox. 
          From there, you can export to zip or just start hacking. 
        </p>
      </Message>

      
      <Segment vertical>
        <Button primary onClick={handleBuild}>Open sandbox</Button>
      </Segment>

      <Segment vertical>
        <a onClick={onStartOver}>Start over</a>
      </Segment>

    </div>
  )
}