import React, { Component } from 'react';
import { Route } from 'react-router';

import Home from './components/Home';
import { AppLayout } from '@findrobbrodie/find-rob-brodie-common'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <>
        <AppLayout>          
          <Route exact path='/' component={Home} />
        </AppLayout>
      </>
    );
  }
}
