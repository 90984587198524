import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'semantic-ui-css/semantic.min.css'
import { AppStateProvider } from './hooks/appStateContext'
//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const initialState = {
  baseUrl
}

ReactDOM.render(
  <AppStateProvider initialState={initialState}>
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  </AppStateProvider>,
  rootElement);

//registerServiceWorker();

