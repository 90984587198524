import React, { useState, useRef } from 'react'
import {
  Segment,
  Message,
  Button
} from 'semantic-ui-react'
import Editor from "@monaco-editor/react"
import axios from 'axios'
import beautify from 'json-beautify'
import { useAppState } from '../hooks/appStateContext'

export default function StepDefineData({templateProject, onNext}) {
  const [dataDefinition, setDataDefinition] = useState(templateProject.defaultDataDefinition);
  const [typeMapping, setTypeMapping] = useState(templateProject.defaultTypeMapping);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const valueGetter = useRef();
  const appState = useAppState();

  const handleEditorDidMount = _valueGetter => {
    setIsEditorReady(true);
    valueGetter.current = _valueGetter;
  }

  const handleBeautifyClick = () => {
    //not a controlled component. todo figure out of to set value = beautify(valueGetter.current(), null ,'  ', 100)    
  }

  const handleNextClick = () => {
    // validate defaultDataDefinition??

    // Create project
    const post = async () => {
      setFetching(true)
      setError(null)

      try {
        const headers = {
          "Content-Type": "application/json"
        }
        const response = await axios.post(`${appState.baseUrl}api/project/${templateProject.owner}/${templateProject.repo}`,
          { test: valueGetter.current() },
        {
          headers
        })
        
        let url;
        if (!!response.data && !response.errors) {
          const id = response.data;
          url = `https://codesandbox.io/s/${id}`        
        }
        setFetching(false)
        
        onNext(url)
      }
      catch (err) {
        setError(err.response.data);
        setFetching(false)
      }
    }
    post()
  }

  return (
    <div>
      <Message info>
        <Message.Header>Define your data</Message.Header>
        <p>
          Make this template come to life by defining your data.
          The data is the soul of your application and makes your app unique.
          Brodeo Builder let's you define the data upfront so you can work out
          all the little details later.  Life is too short for handwriting boiler plate code.
        </p>
      </Message>

      { !!error &&    
        <Message 
          error
          icon='exclamation'
          header='Error'
          content={error}
        />
      }

      <Segment vertical>
        <Button onClick={handleBeautifyClick}>Beautify</Button>
      </Segment>
      <Segment vertical>
        <Editor
          height="50vh"
          language="json"
          
          value={dataDefinition}
          editorDidMount={handleEditorDidMount}
        />
      </Segment>
      <Segment vertical>
        <Button primary onClick={handleNextClick} loading={fetching}>Next</Button>
      </Segment>

    </div>
  )
}